<template>
  <div class="w_auto">
    <!-- <BreadcrumbNav></BreadcrumbNav> -->
    <div class="bgwhites">   
      <div class="btn-color">
        <span>排序</span>
        <el-tabs v-model="curTab" @tab-click="tabClick">
          <el-tab-pane v-for="(tab,index) in stateTab" :key="index" :label="tab.label" :name="tab.value" />
        </el-tabs>
        <el-divider direction="vertical"></el-divider>
        <ul class="courseul">
          <li :class="{actives:(newIndex==0)}" @click="menuClick(0,'')">全部</li>
          <li
          class=""
          v-for="(item,index) in courseList" 
          :key="index" 
          :class="{actives:(newIndex==(index+1))}"
          @click="menuClick(index+1,item.labelId)"
          >
          {{item.labelName}}
          </li>
        </ul>
        <el-divider direction="vertical"></el-divider>
        <el-cascader
          class="kc-input"
          placeholder="请选择分类"
          v-model="input"
          :options="options"
          @change="handleChange">
        </el-cascader>
      </div>
    </div>
    <div class="mt15">
      <div>
        <el-row :gutter="20">         
          <el-col :span="isPc ? 18 : 24">
            <div class="bagray bagrayns">
            <el-row :gutter="20" v-if="peixunList.length!=0">
              <el-col :span="8" v-for="(i, index) in peixunList" :key="index" class="mt10">
                <el-card :body-style="{ padding: '0px' }" class="hovershadow is-always-shadow">
                  <div class="indpic">
                    <img v-if="i.facePic!=null" :src="i.facePic" class="image" @click="subclick(i.activityId)"/>
                    <img v-else src="@/assets/images/Interna/training.jpg" class="image" @click="subclick(i.activityId)"/>
                    <div class="kcstat flex-betweens">
                      <span
                        ><i class="el-icon-video-camera-solid"></i
                        >{{ i.activityState==0?'未开始':i.activityState==1?"进行中":'已结束' }}</span
                      >
                    </div>
                  </div>
                  <div style="padding: 14px">
                    <span class="bt-title" @click="subclick(i.activityId)">{{i.activityName}}</span>
                    <div class="bottom clearfix">
                      <p>
                        <span>讲师：{{i.teacherName}}</span>
                      </p>
                      <div class="isfree flex-betweens" style="justify-content : flex-end;">
                        <el-button v-if="i.joinWay==0" @click="subclick(i.activityId)">公开培训</el-button>
                        <el-button v-else-if="i.joinWay==1" @click="subclick(i.activityId)">报名培训</el-button>
                        <el-button v-else @click="subclick(i.activityId)">指定培训</el-button>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <div v-else>
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="params.page"
              :page-size.sync="params.limit"
              :total="count"
              @current-change="handleCurrentChange"
              >
            </el-pagination>
          </div>
          </el-col>                 
          <el-col :span="isPc ? 6 : 24">   
            <div class="new-card">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <i class="el-icon-caret-right"></i>
                  <span>培训推荐</span>
                </div>
                <div>
                   <div class="bagray bagrayns">
                    <el-row :gutter="20">
                      <el-col :span="24" v-for="(o, index) in 2" :key="index" class="mt10">
                        <el-card :body-style="{ padding: '0px' }" class="hovershadow is-always-shadow">
                          <div class="indpic">
                            <img src="@/assets/images/Interna/training.jpg" class="image" @click="subclick"/>
                            <div class="kcstat flex-betweens">
                              <span
                                ><i class="el-icon-video-camera-solid"></i
                                >{{ zliveing }}</span
                              >
                            </div>
                          </div>
                          <div style="padding: 14px">
                            <span class="bt-title" @click="subclick">学习时长</span>
                            <div class="bottom clearfix">
                              <p>
                                <span>讲师：111</span>
                              </p>
                              <div class="isfree flex-betweens" style="justify-content : flex-end;">
                                <el-button>公开培训</el-button>
                              </div>
                            </div>
                          </div>
                        </el-card>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- <el-empty v-if="true" :image-size="150" description="暂无推荐" /> -->
                </div>
              </el-card>  
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
   
  </div>
</template>

<script>
// import BreadcrumbNav from '@/components/breadCrumbs/Breadcrumb'
export default {
  // components:{ BreadcrumbNav },
  data(){
    return{
      token:localStorage.getItem("pc_token"),
      input:'',
       options: [{
          value: 'zhinan',
          label: '七年级',
          children: [{
            value: 'shejiyuanze',
            label: '工程管理',
            children: [{
              value: 'yizhi',
              label: '一致'
            }, {
              value: 'fankui',
              label: '反馈'
            }, {
              value: 'xiaolv',
              label: '效率'
            }, {
              value: 'kekong',
              label: '可控'
            }]
          }, {
            value: 'daohang',
            label: '直播摄像1',
            children: [{
              value: 'cexiangdaohang',
              label: '侧向导航'
            }, {
              value: 'dingbudaohang',
              label: '顶部导航'
            }]
          }]
        }, {
          value: 'zujian',
          label: '八年级',
          children: [{
            value: 'basic',
            label: '英语学习',
            children: [{
              value: 'layout',
              label: 'Layout 布局'
            }, {
              value: 'color',
              label: 'Color 色彩'
            }, {
              value: 'typography',
              label: 'Typography 字体'
            }, {
              value: 'icon',
              label: 'Icon 图标'
            }, {
              value: 'button',
              label: 'Button 按钮'
            }]
          },]
        }, {
          value: 'ziyuan',
          label: '九年级',
          children: [{
            value: 'axure',
            label: '前沿技术',
            children: [{
              value: 'layout',
              label: '区块链'
            },{
              value: 'layout',
              label: '互联网'
            }]
          }, {
            value: 'sketch',
            label: '互联网技术',
            children: [{
              value: 'layout',
              label: '产品运营'
            },{
              value: 'layout',
              label: '产品策划'
            }]
          }]
        },{
          value: 'other',
          label: '其他',
          children: [{
            value: 'axure',
            label: 'Axure Components'
          }, {
            value: 'sketch',
            label: 'Sketch Templates'
          }, {
            value: 'jiaohu',
            label: '组件交互文档'
          }]
        }],
        peixunList:[],
        courseList:[],
        curTab: 'all',
        newIndex:0,
        labelIdName:'',
        joinWays:'',
        labelIds:'',
        stateTab: [
        {
          "label": "全部",
          "value": "all"
        }, {
          "label": "报名培训",
          "value": "1"
        }, {
          "label": "公开培训",
          "value": "0"
        }
        ],
        zliveing: "正在直播",
        xwzxList:[
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'田老师',jojn:2},
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'李老师',jojn:2},
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'汪老师',jojn:4},
        ],
        params: { limit: 12, page: 1 },
        count: 0,
    }
  },
  mounted(){
    this.getFrontTrainLabelPageApi()
    this.getActivityListApi()
  },
  computed: {
    isPc() {
			return window.screen.width <= 767 ? false : true;
		},
  },
  methods:{
    getFrontTrainLabelPageApi(){
      this.$api.getFrontTrainLabelPageApi({
        channelId: this.$http.channelId
      }).then(res => {
        if(res.code == 10000){
          this.courseList = res.data
        }
      })
    },
    getActivityListApi(){
      if(this.joinWays == "all"){
        this.joinWays = ''
      }
      this.$api.getActivityListApi({
        channelId: this.$http.channelId,
        page: this.params.page,
        limit:this.params.limit,
        typeOne: '',
        typeTwo: '',
        typeThree: '',
        labelId: this.labelIdName,
        joinWay: this.joinWays,
        token:this.token,
      }).then(res => {
        if(res.code == 10000){
          this.peixunList = res.data.data
          this.count = res.data.count
        }
      })
    },
    handleCurrentChange(page){
      this.params.page = page
      this.getActivityListApi()
    },
    tabClick(tab){
      let type = tab.name
      this.joinWays = type
      console.log(type);
      if(type == "all"){
        this.page = 1
        this.getActivityListApi()
      }else{
        this.getActivityListApi()
      }
    },
    menuClick(index,labelId){
      this.newIndex = index;
      this.labelIdName = labelId
      this.getActivityListApi()
    },
     handleChange(value) {
        console.log(value);
      },
    subclick(id){
      this.$router.push({
        path:'/activity/detail',
        query:{activityId:id}
      })
    }
  }
  
}
</script>

<style scoped lang="scss">
 .bgwhites {
    background-color: #fff;
    padding: 16px;
    .btn-color{
    display: flex;
    align-items: center;   
    font-size: 14px;    
    }
    .btn-color span{
      padding-right:15px;
    }
} 
.actives {
  color: #18cca7;
}
.mt15 {
  margin-top: 15px;
  .bagray{
    padding: 10px 20px 15px; 
    background-color: #fafafa; 
    // border-radius: 5px;
    box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 10%) 
  }
  .bagrayns{
    box-shadow:none
  }
  .indpic {
    position: relative;
    .image {
      width: 100%;
      height: 1.07rem;
      display: block;
      cursor: pointer;
    }
    .kcstat {
      position: absolute;
      width: 100%;
      padding: 5px;
      bottom: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
    .kcstat i {
      font-size: 20px;
      color: #f00;
      margin-right: 5px;
    }   
  }
  .bottom {
    margin-top: 3px;
    line-height: 12px;
  }
  .bottom p {
    font-size: 12px;
    color: #ccc;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  .isfree {
    margin-top: 10px;
  }
  .isfree span {
    color: #f00;
  }
  .isfree button {
    padding: 7px 10px;
    background: linear-gradient(#f47349 0%, #f0955d 70%);
    border-radius: 3px;
    color: #fff;
  }
  .flex-betweens{
      display: flex;
      justify-content : space-between;
      align-items: center; 
      align-content: center;
    }
  }
.pagination{
  display: flex;
  justify-content: flex-end;
  margin: 5% 0;
}
.hovershadow:hover {
    box-shadow: 0 0 5px 0 #c7c7c7, 0 1px 8px 0 #c7c7c7;
    transform: translateY(-3px);
  }
  .is-always-shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
.bt-title{
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
.bt-title:hover{
  color: #3ce4bc;
}
.cktj{
  color:#000;
  font-size:12px
}
.courseul{
  display: flex;
}
.courseul li{
  padding: 0 15px;
  cursor: pointer;
}
.btn-color ::v-deep .el-button--primary{
  background:linear-gradient(#3ce4bc 0%, #68b9cd 70%) !important;
}
.kc-input{
  width: 27%;
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.new-card::v-deep .el-card__header{
  padding: 10px 20px 15px; 
}
.new-card::v-deep .el-card__body {
  padding: 1px;
}
// 排序开始
.btn-color::v-deep .el-tabs__nav-wrap::after{
  height: 0px;
}
.btn-color::v-deep .el-tabs__active-bar{
  height: 0px;
}
.btn-color::v-deep .el-tabs__header{
  margin: 0 0 1px
}
.btn-color::v-deep .el-tabs__item{
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .w_auto {
    width: 100%;
  }
  .bgwhites {
    .btn-color {
      flex-wrap: wrap;
    }
    .kc-input {
      width: 100%;
    }
  }
  .mt15 {
    .indpic {
      .image {
        height: 40vw;
      }
    }
  }
}
</style>